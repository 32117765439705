<template>
  <div class="activate">
    <main-titlebar myclass="redBar" showback="true"/>
    

      <div class="grid-box-vscale">
        <p>
          <img id="profile-img" :src="logo" class="profile-img-card" alt="TripTrader Logo"/>
        </p>
      </div>

      <div v-if="!successful">
    <Form @submit="handleActivate" :validation-schema="schema">
      <Field name="recaptchaValue" type="hidden" class="form-input" v-model="recaptchaValue" placeholder="recaptchaValue"/>
      <Field name="key" type="hidden" class="form-input" v-model="key" placeholder="key"/>
      <Field name="email" type="hidden" class="form-input" v-model="email" placeholder="key"/>


        <p>Bevestig en activeer uw account en ga aan de slag met TripTrader!</p>
 
      <div class="" style="text-align:center;display:inline-block;">
            <vue-recaptcha v-show="showRecaptcha" sitekey="6Lcw2RghAAAAAFbSx6CTsBboNYdhufABBGxTEti6"
            size="compact" 
            theme="light"
            hl="en"
            @verify="recaptchaVerified"
            @expire="recaptchaExpired"
            @fail="recaptchaFailed"
            ref="vueRecaptcha">
          </vue-recaptcha>
        </div>
       <div class="form-group">     
          <ErrorMessage name="recaptchaValue" class="error-feedback" />
        </div>


      <div class="trip-view-row">
        <div class="trip-view-col">
          <button class="default-button" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Activeren
          </button>
        </div>
      </div>
    </Form>
    </div>
      <div class="form-group"> 
        <div
          v-if="message"
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </div>

      <div class="form-group">&nbsp;</div>

      <div v-if="successful">
        <div class="form-group"> 
          <div class="register-button" @click="gotoLogin" :disabled="loading">Aanmelden</div>
        </div>
      </div>
   
  </div>
</template>
<script>
import vueRecaptcha from 'vue3-recaptcha2';
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Activate",
  data() {
    const schema = yup.object().shape({
     

      recaptchaValue: yup
        .string()
        .required("You must confirm ")
        .oneOf(["1"], "Bevestig dat u geen robot bent")
        ,
    });
    return {
      logo:require('../../assets/triptrader-logo.png'),
      key: this.$route.query.key,
      email: this.$route.query.email,
      successful: false,
      loading: false,
      message: "",
      schema,
      showRecaptcha: true,
      recaptchaValue: "0",
    };
  },
  mounted() {
     console.log(this.$route.query.key);
  },
  methods: {
    gotoLogin() {
      this.$router.push('/login');
    },
    recaptchaVerified(response) {
      console.log("recaptchaVerified:" + response);
      this.recaptchaValue = "1";

    },
    recaptchaExpired() {
      this.recaptchaValue = "0";

      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.recaptchaValue = "0";

    },
    handleActivate(user) {
      this.loading = true;
      this.$store.dispatch("auth/activate", user).then(
        (data) => {
          console.log("activation data: " + data);
          if(data.status == "failed") {
            this.message = data.message;
            this.successful = false;
            this.loading = false;
          } else {
            this.message = "Account geactiveerd. U kunt nu aanmelden op TripTrader";
            this.successful = true;
            this.loading = false;

          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
  computed: {

 
  },
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    Form,
    Field,
    ErrorMessage,
    vueRecaptcha,

  }
};
</script>